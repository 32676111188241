import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from 'store/types';
import { signIn, resetPassword, updatePassword, getProfile, updatePersonalInfo, getTwilioToken } from 'store/actions/user-action';
import { ServerError } from 'shared/types';
import { getDecodedToken } from 'shared/utils';
import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const initialState: UserState = {
  profile: null,
  twilioToken: null,
  error: null,
  loading: {
    signIn: false,
    passwordReset: false,
    passwordUpdate: false,
    getProfile: false,
    updateFullname: false,
    getTwilioToken: false,
  },
  session: getDecodedToken(),
  firebaseToken: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFirebaseToken: (state, { payload }: PayloadAction<string | null>) => {
      state.firebaseToken = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // ============= SIGN-IN ============= //
      .addCase(signIn.pending, (state) => {
        state.loading.signIn = true;
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.loading.signIn = false;
        cookies.set('access_token', payload.access_token);
        state.session = getDecodedToken();
      })
      .addCase(signIn.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.signIn = false;
        state.error = action.payload.message;
      })
      // ============= RESET PASSWORD ============= //
      .addCase(resetPassword.pending, (state) => {
        state.loading.passwordReset = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading.passwordReset = false;
      })
      .addCase(resetPassword.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.passwordReset = false;
        state.error = action.payload.message;
      })
      // ============= UPDATE PASSWORD ============= //
      .addCase(updatePassword.pending, (state) => {
        state.loading.passwordUpdate = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.loading.passwordUpdate = false;
      })
      .addCase(updatePassword.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.passwordUpdate = false;
        state.error = action.payload.message;
      })
      // ============= GET PROFILE ============= //
      .addCase(getProfile.pending, (state) => {
        state.loading.getProfile = true;
        state.error = null;
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.loading.getProfile = false;
        state.profile = payload;
      })
      .addCase(getProfile.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getProfile = false;
        state.error = action.payload.message;
      })
      // ============= UPDATE FULLNAME ============= //
      .addCase(updatePersonalInfo.pending, (state) => {
        state.loading.updateFullname = true;
        state.error = null;
      })
      .addCase(updatePersonalInfo.fulfilled, (state, { payload }) => {
        state.loading.updateFullname = false;
        if (state.profile) {
          state.profile.firstName = payload.firstName;
          state.profile.lastName = payload.lastName;
        }
      })
      .addCase(updatePersonalInfo.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.updateFullname = false;
        state.error = action.payload.message;
      })
      // ============= UPDATE FULLNAME ============= //
      .addCase(getTwilioToken.pending, (state) => {
        state.loading.getTwilioToken = true;
        state.error = null;
      })
      .addCase(getTwilioToken.fulfilled, (state, { payload }) => {
        cookies.set('twilio_token', payload.twilio_token)
        state.twilioToken = jwtDecode(payload.twilio_token);
        state.loading.getTwilioToken = false;
      })
      .addCase(getTwilioToken.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getTwilioToken = false;
        state.error = action.payload.message;
      })
  },
});

export default userSlice.reducer;
export const { setFirebaseToken } = userSlice.actions;

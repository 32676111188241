import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import Cookies from "js-cookie";
import repo from "./repository";
import { getDeviceID } from "./utils";

const firebaseConfig = {
  apiKey: "AIzaSyD2gE01JwmZRSVn85mBwA6XPMD4uPElPOQ",
  authDomain: "hav-wtr-dev-3bb90.firebaseapp.com",
  projectId: "hav-wtr-dev-3bb90",
  storageBucket: "hav-wtr-dev-3bb90.appspot.com",
  messagingSenderId: "839341595366",
  appId: "1:839341595366:web:e58f0ae38697ced4d8f927",
  measurementId: "G-KQ4S58FMCD"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

const { REACT_APP_VAPID_KEY } = process.env;

export default messaging;

export const getFirebaseToken = async () => {
  try {
    let token = Cookies.get('firebase_token');

    if (token) return token;

    // const permission = await Notification.requestPermission();
    // if (permission !== 'granted') {
    //   Cookies.remove('firebase_token', { path: '/' });
    //   return null;
    // }

    token = await getToken(messaging, { vapidKey: REACT_APP_VAPID_KEY });

    if (!token) {
      Cookies.remove('firebase_token', { path: '/' });
      return null;
    }

    await repo.post('/default/push-token', {
      pushToken: token,
      deviceId: getDeviceID(),
      deviceType: 'web'
    });
    Cookies.set('firebase_token', token, { path: '/' });

    return token;
  } catch (err) {
    console.error(err);
    return null;
  }
}
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import repo from 'shared/repository';
import { EventInfo } from 'shared/types';
import { base64ToBlob, isBase64, validateServerError } from 'shared/utils';

export const getEvents = createAsyncThunk('events/get', async (_, { rejectWithValue }) => {
  try {
    const response = await repo.get(`/events`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const getOneEvent = createAsyncThunk('event/getOne', async (eventId: string, { rejectWithValue }) => {
  try {
    const response = await repo.get(`/event/${eventId}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const createEvent = createAsyncThunk('event/create', async (body: any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    for (const [key, value] of Object.entries(body)) {
      if (key === 'image') {
        for (let image of body.image) {
          if (isBase64(image)) {
            image = await base64ToBlob(image);
            formData.append('image', image, 'filename.png');
          } else {
            formData.append('image', image);
          }
        }
      } else {
        formData.append(key, value as any)
      }
    }
    const response = await repo.post(`/event`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const updateEvent = createAsyncThunk('event/update', async (data: any, { rejectWithValue }) => {
  try {
    const { eventId, newValues } = data;
    const formData = new FormData();
    for (const [key, value] of Object.entries(newValues)) {
      if (key === 'image') {
        for (let image of newValues.image) {
          if (isBase64(image)) {
            image = await base64ToBlob(image);
            formData.append('image', image, 'filename.png');
          } else {
            formData.append('image', image);
          }
        }
      } else {
        formData.append(key, value as any)
      }
    }
    const response = await repo.put(`/event/${eventId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const deleteEvent = createAsyncThunk('event/delete', async (event: EventInfo, { rejectWithValue }) => {
  try {
    const { eventId, created } = event;
    const config: AxiosRequestConfig = {
      params: { created },
    };
    const response = await repo.delete(`/event/${eventId}`, config);

    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

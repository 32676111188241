import CloseIcon from '@mui/icons-material/Close';
import { Alert, CircularProgress, Grid, IconButton, MenuItem, Paper, Select } from "@mui/material";
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType } from '@zxing/library';
import { useEffect, useRef, useState } from "react";

interface BarcodeScannerV2I {
  onClose: () => void;
  onResult: (result: string, stopStream?: () => void) => any | Promise<any>;
  loading?: boolean;
}

const reader = new BrowserMultiFormatReader(new Map<DecodeHintType, any>([
  [ DecodeHintType.POSSIBLE_FORMATS, [ BarcodeFormat.CODE_128 ] ],
  // [ DecodeHintType.TRY_HARDER, true ]
]));

const BarcodeScannerV2 = ({ onResult, onClose, loading }: BarcodeScannerV2I) => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo | null>(null);
  const [error, setError] = useState<string | null>(null);
  const cameraRef = useRef<HTMLVideoElement>(null);
  const decodedResult = useRef<string | null>(null);
  const timeoutId = useRef<any>(null);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 5000);
    }
  }, [error]);

  const stopStream = () => {
    let stream = cameraRef?.current?.srcObject;
    if (!!stream && stream instanceof MediaStream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  };

  useEffect(() => {
    (async () => {
      if (cameraRef.current) {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const videoDevicesInfo = mediaDevices.filter(device => device.kind === 'videoinput');
        if (videoDevicesInfo.length) {
          setDevices(videoDevicesInfo);
          const selectedDevice = videoDevicesInfo[0];
          const videoDevice = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: selectedDevice.deviceId },
            audio: false,
          });
          setSelectedDevice(selectedDevice);
          cameraRef.current.srcObject = videoDevice;
        } else {
          setError('No available camera devices')
        }
      }

      return stopStream;
    })();
  }, [cameraRef.current]);
  
  const decodeBarcode = async () => {
    if (selectedDevice && cameraRef.current) {
      reader.reset();
      const result = (await reader.decodeOnceFromVideoDevice(selectedDevice.deviceId, cameraRef.current)).getText();
      if (result !== decodedResult.current) {
        decodedResult.current = result;
        const success = await onResult(result, stopStream);
        if (!success && !timeoutId.current) {
          timeoutId.current = setTimeout(() => {
            timeoutId.current = null;
            decodeBarcode();
          }, 2000);
        }
      }
    }
  }

  const changeDevice = async () => {
    if (selectedDevice && cameraRef.current) {
      const videoDevice = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: selectedDevice.deviceId },
        audio: false,
      });
      cameraRef.current.srcObject = videoDevice;
    }
  }

  useEffect(() => {
    changeDevice();
    decodeBarcode();
  }, [cameraRef.current, selectedDevice])

  return (
    <Grid width='100%' height='100%' position='relative'>
      <video
        style={{ width: '100%', height: '100%' }}
        autoPlay
        ref={cameraRef}
      />
      <Grid sx={{
        position: 'absolute',
        width: '100%',
        height: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        top: 0
      }}>
        {loading && <CircularProgress/>}
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 2,
          width: '100%',
          position: 'absolute',
          bottom: '80px'
        }}
      >
        <Grid
          component={Paper}
          sx={{ padding: '12px' }}
        >
          <Select
            disabled={!devices.length}
            value={selectedDevice}
            onChange={(e) => setSelectedDevice(e.target.value as any)}
            inputProps={{
              sx: { padding: '6px 10px' }
            }}
          >
            {devices.map((device) => (
              // @ts-ignore
              <MenuItem key={device.deviceId} value={device}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
          <IconButton onClick={() => {
            stopStream();
            onClose();
          }}>
            <CloseIcon/>
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )

}

export default BarcodeScannerV2;
export const APP_PATH = {
  SIGN_IN: '/sign-in',
  RESET_PASSWORD: '/sign-in/reset',

  PRODUCTS: '/products',
  PRODUCT_CREATE: '/products/create',
  PRODUCT_DETAILS: '/products/:name',
  PRODUCT_EDIT: '/products/edit/:name',
  PRODUCTS_TYPES: '/products/types',

  USERS: '/users',
  USER_DETAILS: '/users/:id',

  PROFILE: '/profile',

  ORDERS: '/orders',

  ADMINISTRATION: '/administration',

  SUBSCRIPTIONS: '/subscriptions',

  CHAT: '/chat',

  EVENTS: '/events',
  EVENT_CREATE: '/events/create',
  EVENT_DETAILS: '/events/:eventId',
  EVENT_EDIT: '/events/edit/:eventId',
};

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_FORMAT_2 = 'd MMM yyyy';
export const TIME_FORMAT = 'h:mm aaa';
export const DATE_TIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT;

import { createSlice } from '@reduxjs/toolkit';
import { ServerError, UserInfo, UserRoles } from 'shared/types';
import { addLoyaltyPoints, getSelectedParent, getSelectedUser, getSelectedUserPointsHistory, getUsers, updateLoyaltyAsset, verifyBarcode } from 'store/actions/users-action';
import { UsersState } from 'store/types';
import { updateUsers } from './../actions/users-action';

const initialState: UsersState = {
  users: [],
  familyUsers: [],
  selectedUser: null,
  selectedParents: [],
  selectedUserPointsHistory: [],
  error: null,
  loading: {
    table: false,
    update: false,
    getSelectedUser: true,
    getSelectedParent: true,
    getSelectedUserPointsHistory: true,
  },
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSelectedUser(state, { payload }) {
      state.selectedUser = state.users.find((user) => user.userId === payload) || null;
    },
    setSelectedParents(state, { payload }) {
      state.selectedParents = state.familyUsers.filter((user) => user.parentId === payload);
    },
    setSelectedLoading(state, { payload }) {
      state.loading.getSelectedUser = payload;
      state.loading.getSelectedParent = payload;
      state.loading.getSelectedUserPointsHistory = payload;
    }
  },
  extraReducers: (builder) => {
    builder
    
      // ============ GET USERS ============ //
      .addCase(getUsers.pending, (state) => {
        state.loading.table = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.loading.table = false;
        state.users = (payload as UserInfo[]).filter((u) => u.userRole === UserRoles.Client);
        state.familyUsers = (payload as UserInfo[]).filter((u) => u.userRole === UserRoles.Family);
      })
      .addCase(getUsers.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.table = false;
        state.error = action.payload.message;
      })

      // ============ UPDATE USERS ============ //
      .addCase(updateUsers.pending, (state) => {
        state.loading.update = true;
        state.error = null;
      })
      .addCase(updateUsers.fulfilled, (state, { payload }) => {
        const index = state.users.findIndex((user) => user.userId === payload.userId);
        if (index !== -1) {
          state.users[index].state = payload.state;
        }
        state.loading.update = false;
      })
      .addCase(updateUsers.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.update = false;
        state.error = action.payload.message;
      })

      // ============ GET SELECTED USER ============ //
      .addCase(getSelectedUser.pending, (state) => {
        state.loading.getSelectedUser = true;
        state.error = null;
      })
      .addCase(getSelectedUser.fulfilled, (state, { payload }) => {
        state.loading.getSelectedUser = false;
        state.selectedUser = payload
      })
      .addCase(getSelectedParent.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getSelectedParent = false;
        state.error = action.payload.message;
      })

      // ============ GET SELECTED PARENT ============ //
      .addCase(getSelectedParent.pending, (state) => {
        state.loading.getSelectedParent = true;
        state.error = null;
      })
      .addCase(getSelectedParent.fulfilled, (state, { payload }) => {
        state.loading.getSelectedParent = false;
        state.selectedParents = payload || [];
      })
      .addCase(getSelectedUser.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getSelectedUser = false;
        state.error = action.payload.message;
      }) 

      // ============ UPDATE SELECTED USER LOYALTY ASSET ============ //
      .addCase(updateLoyaltyAsset.pending, (state, { meta }) => {
        state.loading[`loyaltyAsset${meta.arg.created}`] = true;
        state.error = null;
      })
      .addCase(updateLoyaltyAsset.fulfilled, (state, { meta }) => {
        state.loading[`loyaltyAsset${meta.arg.created}`] = false;
        if (!state.selectedUserPointsHistory.length) return;
        const temp = [ ...state.selectedUserPointsHistory ];
        const ind = temp.findIndex((item) => (
          item.created === meta.arg.created && item.userId === meta.arg.userId && !!item.asset
        ));
        if (ind === -1 || !temp[ind].asset) return;
        // @ts-ignore
        temp[ind].asset.status = meta.arg.assetStatus;
        state.selectedUserPointsHistory = temp;
      })
      .addCase(updateLoyaltyAsset.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading[`loyaltyAsset${action.meta.arg.created}`] = false;
        state.error = action.payload.message;
      })

      // ============ ADD LOYALTY POINTS ============ //
      .addCase(addLoyaltyPoints.pending, (state, { meta }) => {
        state.loading[`addPoints${meta.arg.userId}`] = true;
        state.error = null;
      })
      .addCase(addLoyaltyPoints.fulfilled, (state, { meta }) => {
        state.loading[`addPoints${meta.arg.userId}`] = false;
        if (state.selectedUser && state.selectedUser.userId === meta.arg.userId) {
          state.selectedUser.availableLoyaltyPoints += meta.arg.points;
        }
        const ind = state.users.findIndex((u) => u.userId === meta.arg.userId);
        if (typeof state.users[ind]?.availableLoyaltyPoints === 'number') {
          state.users[ind].availableLoyaltyPoints += meta.arg.points
        }
      })
      .addCase(addLoyaltyPoints.rejected, (state, action: any) => {
        state.loading[`addPoints${action.meta.arg.userId}`] = true;
        state.error = action.payload.message;
      })

      // ============ GET SELECTED USER POINTS HISTORY ============ //
      .addCase(getSelectedUserPointsHistory.pending, (state) => {
        state.loading.getSelectedUserPointsHistory = true;
        state.error = null;
      })
      .addCase(getSelectedUserPointsHistory.fulfilled, (state, action) => {
        state.loading.getSelectedUserPointsHistory = false;
        state.selectedUserPointsHistory = action.payload;
      })
      .addCase(getSelectedUserPointsHistory.rejected, (state, action: any) => {
        state.loading.getSelectedUserPointsHistory = false;
        state.error = action.payload.message;
      })

      // ============ VERIFY BARCODE ============ //
      .addCase(verifyBarcode.pending, ( state ) => {
        state.loading.barcodeVerify = true;
      })
      .addCase(verifyBarcode.fulfilled, ( state ) => {
        state.loading.barcodeVerify = false;
      })
      .addCase(verifyBarcode.rejected, ( state, action: any ) => {
        state.loading.barcodeVerify = false;
        state.error = action?.payload?.message || null;
      })
    },
});

export const { setSelectedUser, setSelectedParents, setSelectedLoading } = userSlice.actions;
export default userSlice.reducer;

import user from './user-reducer';
import products from './products-reducer';
import users from './users-reducer';
import orders from './orders-reducer';
import administration from './administration-reducer';
import subscriptions from './subscriptions-reducer';
import chat from './chat-reducer';
import events from './events-reducer';

export default { user, products, users, orders, administration, subscriptions, chat, events };

import { createSlice } from '@reduxjs/toolkit';
import { EventInfo, EventsTabState, ServerError } from 'shared/types';
import { createEvent, deleteEvent, getEvents, getOneEvent, updateEvent } from 'store/actions/events-action';
import { EventsState } from 'store/types';

const initialState: EventsState = {
  events: [],
  selectedEvent: null,
  selectedEventsTabState: EventsTabState.ACTIVE,
  error: null,
  loading: {
    table: false,
    create: false,
    update: false,
    getSelectedEvent: false,
  },
};

const eventsReducer = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setSelectedEvent(state, { payload }) {
      state.selectedEvent = state.events.find((event) => event.eventId === payload) || null;
    },
    setSelectedEventsTabState(state, { payload }) {
      state.selectedEventsTabState = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // ============ GET EVENTS ============ //
      .addCase(getEvents.pending, (state) => {
        state.loading.table = true;
        state.error = null;
      })
      .addCase(getEvents.fulfilled, (state, { payload }) => {
        state.loading.table = false;
        state.events = payload as EventInfo[];
      })
      .addCase(getEvents.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.table = false;
        state.error = action.payload.message;
      })
      // ============ GET ONE EVENT ============ //
      .addCase(getOneEvent.pending, (state) => {
        state.loading.getOne = true;
        state.error = null;
        state.selectedEvent = null;
      })
      .addCase(getOneEvent.fulfilled, (state, { payload }) => {
        state.loading.getOne = false;
        state.selectedEvent = payload as EventInfo;
      })
      .addCase(getOneEvent.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getOne = false;
        state.error = action.payload.message;
      })
      // ============ CREATE EVENT ============ //
      .addCase(createEvent.pending, (state) => {
        state.loading.create = true;
        state.error = null;
      })
      .addCase(createEvent.fulfilled, (state, { payload }) => {
        state.events = [...state.events, payload].sort((a, b) => b.date - a.date);
        state.loading.create = false;
      })
      .addCase(createEvent.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.create = false;
        state.error = action.payload.message;
      })
      // ============ UPDATE EVENTS ============ //
      .addCase(updateEvent.pending, (state) => {
        state.loading.update = true;
        state.error = null;
      })
      .addCase(updateEvent.fulfilled, (state, { payload }) => {
        const index = state.events.findIndex((event) => event.eventId === payload.eventId);
        if (index !== -1) {
          const previousDate: number = state.events[index].startDate;
          state.events[index] = payload;

          if (previousDate !== payload.date) {
            state.events.sort((a, b) => b.startDate - a.startDate);
          }
        }
        state.loading.update = false;
      })
      .addCase(updateEvent.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.update = false;
        state.error = action.payload.message;
      })
      // ============ DELETE EVENTS ============ //
      .addCase(deleteEvent.pending, (state) => {
        state.loading.delete = true;
        state.error = null;
      })
      .addCase(deleteEvent.fulfilled, (state, { meta }) => {
        state.loading.delete = false;
        state.events = state.events.filter((event) => meta.arg.eventId !== event.eventId);
      })
      .addCase(deleteEvent.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.delete = false;
        state.error = action.payload.message;
      });
  },
});

export const { setSelectedEvent, setSelectedEventsTabState } = eventsReducer.actions;
export default eventsReducer.reducer;

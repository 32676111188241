import { LinearProgress } from '@mui/material';
import { Client } from '@twilio/conversations';
import { APP_PATH } from 'app.constants';
import Cookies from 'js-cookie';
import React, { FC, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import SidebarGrid from 'shared/components/common/sidebar-grid';
import { getFirebaseToken } from 'shared/firebase';
import { Notification, NotificationType } from 'shared/types';
import { parseNotification } from 'shared/utils';
import { addMessage, getConversations } from 'store/actions/chat-action';
import { getTwilioToken } from 'store/actions/user-action';
import { setChatGlobalLoading, setConnectionState } from 'store/reducers/chat-reducer';
import { addNewOrders, incrementNewOrdersAmount } from 'store/reducers/orders-reducer';
import { addNewPaymentMethod, addNewSub, incrementNewSubsAmount } from 'store/reducers/subscriptions-reducer';
import { setFirebaseToken } from 'store/reducers/user-reducer';
import { userFirebaseTokenSelector, userSessionSelector } from 'store/selectors/user-selectors';
import { SessionToken } from 'store/types';

interface RouteI {
  element: any;
  elementProps?: any;
  session: SessionToken | null;
  hasSidebar?: boolean;
}

// ======= private route ======= //
const PrivateRoute: FC<RouteI> = ({ element: Element, elementProps, session, hasSidebar, }) => {
  return session ? (
    <Suspense fallback={<LinearProgress />}>
      <SidebarGrid element={Element} elementProps={elementProps} hasSidebar={hasSidebar} />
    </Suspense>
  ) : (
    <Navigate to={APP_PATH.SIGN_IN} />
  );
};

// ======= public route ======= //
const PublicRoute: FC<RouteI> = ({ element: Element, session }) => (
  <Suspense fallback={<LinearProgress />}>{session ? <Navigate to={APP_PATH.USERS} /> : <Element />}</Suspense>
);

// ======= pages ======= //
const SignInPage = React.lazy(() => import('./shared/pages/sign-in'));
const ResetPasswordPage = React.lazy(() => import('./shared/pages/reset-password'));
const ProductsPage = React.lazy(() => import('./shared/pages/products'));
const ProductCreatePage = React.lazy(() => import('./shared/pages/product-create'));
const ProductDetailsPage = React.lazy(() => import('./shared/pages/product-details'));
const ProductEditPage = React.lazy(() => import('./shared/pages/product-edit'));
const ProductTypesPage = React.lazy(() => import('./shared/pages/product-types'));
const UsersPage = React.lazy(() => import('./shared/pages/users'));
const UserDetailsPage = React.lazy(() => import('./shared/pages/user-details'));
const ProfilePage = React.lazy(() => import('./shared/pages/profile'));
const OrdersPage = React.lazy(() => import('./shared/pages/orders'));
const AdministrationPage = React.lazy(() => import('./shared/pages/administration'));
const SubscriptionsPage = React.lazy(() => import('./shared/pages/subscriptions'));
const ChatPage = React.lazy(() => import('./shared/pages/chat'));
const EventsPage = React.lazy(() => import('./shared/pages/events'));
const CreateEventPage = React.lazy(() => import('./shared/pages/event-create'));
const EventDetailsPage = React.lazy(() => import('./shared/pages/event-details'));
const EventEditPage = React.lazy(() => import('./shared/pages/event-edit'));

const AppRoutes = () => {
  const dispatch = useDispatch();
  const session = useSelector(userSessionSelector);
  const firebaseToken = useSelector(userFirebaseTokenSelector);
  const [chatClient, setChatClient] = useState<Client | null>(null);

  const {
    SIGN_IN,
    PRODUCTS,
    PRODUCT_CREATE,
    PRODUCT_DETAILS,
    PRODUCT_EDIT,
    PRODUCTS_TYPES,
    USERS,
    USER_DETAILS,
    RESET_PASSWORD,
    PROFILE,
    ORDERS,
    ADMINISTRATION,
    SUBSCRIPTIONS,
    CHAT,
    EVENTS,
    EVENT_CREATE,
    EVENT_DETAILS,
    EVENT_EDIT,
  } = APP_PATH;

  useEffect(() => {
    if (!session) return;

    dispatch<any>(getTwilioToken()).then((response: any) => {
      const twilio_token = response?.payload?.twilio_token;
      if (twilio_token && typeof twilio_token === 'string') {
        setChatClient(new Client(twilio_token));
      }
    });

    getFirebaseToken().then((token) => {
      dispatch<any>(setFirebaseToken(token));
    })

    getFirebaseToken();
  }, [session]);

  useEffect(() => {
    dispatch<any>(setChatGlobalLoading(true));
    if (!chatClient) return;

    chatClient
      .on('connectionStateChanged', async (state) => {
        dispatch(setConnectionState(state));
        if (state === 'connected') {
          dispatch<any>(setChatGlobalLoading(false));
          dispatch<any>(getConversations({ client: chatClient, payload: undefined }));
        }
      })
      .on('tokenExpired', () => {
        dispatch<any>(getTwilioToken());
      })
      .on('tokenAboutToExpire', () => {
        dispatch<any>(getTwilioToken());
      })
      .on('messageAdded', (message) => {
        dispatch<any>(addMessage({ client: chatClient, payload: message }));
      });

    return () => {
      chatClient.removeAllListeners();
    };
  }, [chatClient]);

  useEffect(() => {
    if (!firebaseToken) return;

    const notificationsHandler = (notifications: Notification[]) => {
      notifications.forEach(({ title, body }) => {
        switch (title) {
          case NotificationType.NEW_ORDER: {
            dispatch<any>(addNewOrders([ body.order ]));
            dispatch<any>(incrementNewOrdersAmount(window.location.pathname === APP_PATH.ORDERS ? 0 : undefined));
            break;
          }
          case NotificationType.NEW_SUBSCRIPTION: {
            dispatch<any>(addNewSub(body.subscription));
            dispatch<any>(addNewPaymentMethod(body.subscription.paymentMethod));
            dispatch<any>(incrementNewSubsAmount(window.location.pathname === APP_PATH.SUBSCRIPTIONS ? 0 : undefined));
            break;
          }
        }
  
      })
    }

    const messageHandler = (event: MessageEvent<any>) => {
      const notification = parseNotification(event?.data?.data);
      if (!notification) return;
      notificationsHandler([ notification ]);
    }
    navigator.serviceWorker.addEventListener('message', messageHandler);

    const visibilityStateHandler = () => {
      if (document.visibilityState !== 'visible') return;
      const notifications: Notification[] = JSON.parse(Cookies.get('bg_notifications') || '[]')
        .map((raw: any) => parseNotification(raw))
        .filter((n: any) => !!n);
      if (notifications?.length) {
        notificationsHandler(notifications);
      }
      Cookies.set('bg_notifications', '[]');
    }
    document.addEventListener('visibilitychange', visibilityStateHandler)

    return () => {
      navigator.serviceWorker.removeEventListener('message', messageHandler);
      document.removeEventListener('visibilitychange', visibilityStateHandler);
    }

  }, [firebaseToken]);

  return (
    <Routes>
      {/* PRIVATE */}
      <Route
        path={PRODUCTS}
        element={<PrivateRoute session={session} element={ProductsPage} hasSidebar />}
      />
      <Route
        path={PRODUCT_CREATE}
        element={<PrivateRoute session={session} element={ProductCreatePage} hasSidebar />}
      />
      <Route
        path={PRODUCT_DETAILS}
        element={<PrivateRoute session={session} element={ProductDetailsPage} hasSidebar />}
      />
      <Route
        path={PRODUCT_EDIT}
        element={<PrivateRoute session={session} element={ProductEditPage} hasSidebar />}
      />
      <Route
        path={PRODUCTS_TYPES}
        element={<PrivateRoute session={session} element={ProductTypesPage} hasSidebar />}
      />
      <Route
        path={USERS}
        element={<PrivateRoute session={session} element={UsersPage} hasSidebar />}
      />
      <Route
        path={USER_DETAILS}
        element={<PrivateRoute session={session} element={UserDetailsPage} hasSidebar />}
      />
      <Route
        path={PROFILE}
        element={<PrivateRoute session={session} element={ProfilePage} hasSidebar />}
      />
      <Route
        path={ORDERS}
        element={<PrivateRoute session={session} element={OrdersPage} hasSidebar />}
      />
      <Route
        path={ADMINISTRATION}
        element={<PrivateRoute session={session} element={AdministrationPage} hasSidebar />}
      />
      <Route
        path={SUBSCRIPTIONS}
        element={<PrivateRoute session={session} element={SubscriptionsPage} hasSidebar />}
      />
      <Route
        path={CHAT}
        element={
          <PrivateRoute
            session={session}
            element={ChatPage}
            elementProps={{ chatClient }}
            hasSidebar
          />
        }
      />
      <Route
        path={EVENTS}
        element={<PrivateRoute session={session} element={EventsPage} hasSidebar />}
      />
      <Route
        path={EVENT_CREATE}
        element={<PrivateRoute session={session} element={CreateEventPage} hasSidebar />}
      />
      <Route
        path={EVENT_DETAILS}
        element={<PrivateRoute session={session} element={EventDetailsPage} hasSidebar />}
      />
      <Route
        path={EVENT_EDIT}
        element={<PrivateRoute session={session} element={EventEditPage} hasSidebar />}
      />

      {/* PUBLIC */}
      <Route path={SIGN_IN} element={<PublicRoute session={session} element={SignInPage} />} />
      <Route path={RESET_PASSWORD} element={<PublicRoute session={session} element={ResetPasswordPage} />} />

      {/* DEFAULT */}
      <Route path='/' element={<Navigate to={session ? USERS : SIGN_IN} />} />
      <Route path='*' element={<Navigate to={session ? USERS : SIGN_IN} />} />
    </Routes>
  );
};

export default AppRoutes;

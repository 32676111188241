import { createAsyncThunk } from '@reduxjs/toolkit';
import repo from 'shared/repository';
import { LoyaltyAchievementType, LoyaltyAssetStatus, UserRoles } from 'shared/types';
import { validateServerError } from 'shared/utils';
import { UpdateUserState } from './administration-action';

export const getUsers = createAsyncThunk('users/get', async (types: UserRoles[], { rejectWithValue }) => {
  try {
    const response = await repo.get(`/users?types=${types}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const updateUsers = createAsyncThunk('users/update', async (body: UpdateUserState, { rejectWithValue }) => {
  try {
    const response = await repo.put(`/user/state`, body);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const getSelectedUser = createAsyncThunk('user/get/selected-user', async (id: string, { rejectWithValue }) => {
  try {
    const response = await repo.get(`/user/${id}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const getSelectedParent = createAsyncThunk('user/get/selected-parent', async (id: string, { rejectWithValue }) => {
  try {
    const response = await repo.get(`/users/family-members?userId=${id}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

type UpdateLoyaltyAssetT = {
  userId: string,
  created: number,
  assetStatus: LoyaltyAssetStatus,
}

export const getSelectedUserPointsHistory = createAsyncThunk('loyalty/history', async (userId: string, { rejectWithValue }) => {
  try {
    const response = await repo.get(`/loyalty/history?user_id=${userId}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const updateLoyaltyAsset = createAsyncThunk('loyalty/update-asset', async (dto: UpdateLoyaltyAssetT, { rejectWithValue }) => {
  try {
    const response = await repo.put('/loyalty/history/asset', dto);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

type AddPoints = {
  userId: string,
  points: number,
  description: string,
  achievementType: LoyaltyAchievementType
}

export const addLoyaltyPoints = createAsyncThunk('loyalty/add-points', async (dto: AddPoints, { rejectWithValue }) => {
  try {
    const response = await repo.post('/loyalty/add', dto);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const verifyBarcode = createAsyncThunk('loyalty/verify-barcode', async (barcode: string, { rejectWithValue }) => {
  try {
    const response = await repo.post(`/loyalty/verify-barcode/${barcode}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})
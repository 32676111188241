import App from 'app';
import 'index.css';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import * as serviceWorkerRegistration from './service-worker-registration';
import 'shared/i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render( <App /> );

reportWebVitals();
serviceWorkerRegistration.register();

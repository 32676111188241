import { Grid, Modal } from "@mui/material";
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verifyBarcode } from 'store/actions/users-action';
import BarcodeScannerV2 from '../barcode-scanner-v2';

interface BarcodeScannerModalProps {
  onClose: () => void;
  open: boolean;
}


const BarcodeScannerModal: React.FC<BarcodeScannerModalProps> = ({ onClose, open }) => {
  // const loading = useSelector(usersLoadingSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onResult = async (result: string, stopStream?: () => void) => {
    let response;
    try {
      response = await dispatch<any>(
        verifyBarcode(result)
      );
    } catch (error) {
      return false;
    }
    const userId = response?.payload?.userId;
    if (userId) {
      stopStream && stopStream()
      onClose();
      navigate(`/users/${userId}`);
      return true;
    }
    return false;
  }
  
  return (
    <Modal
      open={open}
      onClose={onClose}

    >
      <Grid sx={{
        height: '100vh',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <BarcodeScannerV2
          onClose={onClose}
          onResult={onResult}        
        />
      </Grid>
    </Modal>
  );
};

export default BarcodeScannerModal;
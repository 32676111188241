import { RootState } from 'store';

export const subsSelector = (state: RootState) => state.subscriptions.subscriptions; 
export const shippingInfoSelector = (state: RootState) => state.subscriptions.shippingInfo;
export const subsLoadingSelector = (state: RootState) => state.subscriptions.loading;
export const subsErrorSelector = (state: RootState) => state.subscriptions.error;
export const paymentMethodsSelector = (state: RootState) => state.subscriptions.paymentMethods;
export const selectedPaymentMethodsSelector = (state: RootState) => state.subscriptions.selectedPaymentMethod;
export const subsDeliveriesSelector = (state: RootState) => state.subscriptions.deliveries;
export const subsCasesSelector = (state: RootState) => state.subscriptions.subscriptionsCases;
export const deliveryGroupIdSelector = (state: RootState) => state.subscriptions.deliveryGroupId;
export const newSubsAmountSelector = (state: RootState) => state.subscriptions.newSubsAmount;
export const subsOpenedSelector = (state: RootState) => state.subscriptions.subsOpened;

